import NewMainPage from './components/NewWebsite/NewMainPage';
import GlobalStyle from './GlobalStyles';

function Main() {

  return (
    <div>
      <GlobalStyle></GlobalStyle>
      <NewMainPage></NewMainPage>
    </div>
  );
}

export default Main;
