export const TextData = [
  {
    id_1: "LEARN TO CODE THROUGH PLAY",
    id_2: "Coding game for kids age 4+\nto learn through play any time and anywhere.",
    id_3: "SKILLS\nOF THE FUTURE",
    id_4: "Go on Miimo adventure, learn to code,\ndevelop problem-solving & critical thinking skills!",
    id_5: "INNOVATIVE\nEDUCATIONAL EXPERIENCE",
    id_6: "Miimo is the best educational resource for\npersonalized learning experience.",
    id_7: "DIGITAL PLAYGROUND\nFOR CREATIVITY",
    id_8: "Create animations and games with code blocks to\nget featured on Miimo World Leaderboard.",
    id_9: "Download Now",
    id_10: "REVOLUTIONARY",
    id_11: "APPROACH TO LEARNING",
    id_12: "Contact Us",
    id_13: "Main Story",
    id_14: "Take Care of Miimo",
    id_15: "Earn coins after every coding lesson. Use them to feed and play with Miimo. Take care of Miimo. See him grow and evolve.",
    id_16: "Take Lessons",
    id_17: "Earn Coins",
    id_18: "Spend Coins",
    id_19: "Create your own animation",
    id_20: "Sandbox",
    id_21: "Create freestyle animations and games in the Sandbox area with code blocks. Show off your coding skills and get featured on the Leaderboard.",
    id_22: "SHOW IT TO THE WORLD",
    id_23: "CREATE YOUR OWN ANIMATION",
    id_24: "MIIMO PROJECT",
    id_25: "Tracking and Control",
    id_26: "Parent Dashboard",
    id_27: "See your child’s learning outcome, progress, achievements, and projects. Set play time limit and monitor your child's screen time in the passcode-locked Parent Dashboard.",
    id_28: "Available only on iPad",
    id_29: "THE MOST ENJOYABLE",
    id_30: "WAY TO LEARN CODING",
    id_31: "Gamified Learning Journey",
    id_32: "Module 1",
    id_33: "Designing Simple Programs",
    id_34: "Motion Blocks (right, left, up, down, hop)",
    id_35: "Start Block",
    id_36: "End Block",
    id_37: "Module 2",
    id_38: "Designing Character Appearances",
    id_39: "Feature Blocks (Say, Hide, Show, Grow, Shrink)",
    id_40: "Module 3",
    id_41: "Designing Simple Conditional Programs",
    id_42: "Edit values in blocks",
    id_43: "Trigger Blocks (start on tap, start on bump, send message, start on receive message)",
    id_44: "Module 4",
    id_45: "Designing Animation with Audio",
    id_46: "Sound Blocks (play sound, record sound)",
    id_47: "Turn blocks (turn left, turn right)",
    id_48: "Repeat Forever Block",
    id_49: "Add several programs in 1 character",
    id_50: "Module 5",
    id_51: "Designing Complex Animation",
    id_52: "Control Blocks (wait, stop, set speed: slow)",
    id_53: "Module 6",
    id_54: "Designing Programs with pattern",
    id_55: "Loop Block",
    id_56: "Set Speed: fast",
    id_57: "Module 7",
    id_58: "Advanced Conditional Programs",
    id_59: "Teleport Block",
    id_60: "Switch Character Block",
    id_61: "Send Message Block (other colors)",
    id_62: "Start on Receive Message Blocks (other colors)",
    id_63: "Module 8",
    id_64: "Advanced Spatial Commands",
    id_65: "Flip Block (vertical & horizontal)",
    id_66: "We create an educational game that is so fun every kid loves learning.",
    id_67: "",
    id_81: "Contact Us",
    id_82: "Facebook",
    id_83: "Instagram",
    id_84: "Line Official",
    id_85: "LinkedIn",
    id_86: "Youtube",
    id_87: "Email",
    id_88: "Other Pages",
    id_89: "About Us",
    id_90: "Privacy Policy",
    id_91: "Terms and Conditions",
    id_92: "Work with Us",
    id_93: "Home",
  },
  {
    id_1: "เรียนรู้การเขียนโค้ด\nผ่านการเล่น",
    id_2: "เกมเรียน Coding สำหรับเด็กอายุ 4 ปีขึ้นไป\nเรียนรู้และสนุกได้ทุกที่ทุกเวลา",
    id_3: "ทักษะ\nแห่งอนาคต",
    id_4: "ออกผจญภัยไปกับ Miimo เรียนรู้การเขียนโค้ด\nพัฒนาทักษะการแก้ไขปัญหาและการคิดอย่างมีวิจารณญาณ",
    id_5: "ประสบการณ์การเรียนรู้\nรูปแบบใหม่",
    id_6: "ตอบโจทย์ผู้ปกครองทุกท่านที่กำลังมองหา\nแหล่งข้อมูลการศึกษาส่วนบุคคลสำหรับเด็ก",
    id_7: "สนามเด็กเล่นดิจิทัลเพื่อพัฒนาทักษะความคิดสร้างสรรค์",
    id_8: "สร้างแอนิเมชันและเกมของตัวเอง\nและแชร์บน Miimo World Leaderboard",
    id_9: "ดาวน์โหลด",
    id_10: "เรียนอย่างสนุก",
    id_11: "เล่นอย่างได้ความรู้",
    id_12: "ติดต่อเรา",
    id_13: "ภารกิจ",
    id_14: "ช่วยดูแล Miimo",
    id_15: "รับเหรียญรางวัลหลังการเรียนทุกบทเพื่อนำมาซื้ออาหารให้ Miimo และเล่นกับเขา สนุกไปกับการได้เห็น Miimo เติบโต",
    id_16: "เรียนรู้",
    id_17: "รับเหรียญ",
    id_18: "ใช้เหรียญ",
    id_19: "สร้างแอนิเมชันของตัวเอง",
    id_20: "Sandbox",
    id_21: "ใช้โค้ดบล็อกที่เรียนมา ลงมือสร้างโปรเจกต์ด้วยตัวเองอย่างอิสระใน Sandbox ฝึกทักษะ coding และแบ่งปันให้เพื่อน ๆ ใน Miimo World Leaderboard",
    id_22: "แบ่งปันให้ทุกคน",
    id_23: "สร้างแอนิเมชันของตัวเอง",
    id_24: "Miimo โปรเจกต์",
    id_25: "ระบบติดตามและควบคุม",
    id_26: "แดชบอร์ดผู้ปกครอง",
    id_27: "ติดตามผลการเรียนรู้และความคืบหน้าของลูกของคุณ ดูโปรเจกต์ที่พวกเขาสร้างได้แบบเรียลไทม์ ตั้งค่าและดูสถิติเวลาการเล่นได้อย่างปลอดภัย",
    id_28: "ใช้ได้เฉพาะบน iPad",
    id_29: "การเรียน Coding",
    id_30: "ที่สนุกที่สุดสำหรับเด็ก",
    id_31: "หลักสูตรการเรียนรู้ในรูปแบบเกม",
    id_32: "โมดูลที่ 1",
    id_33: "การออกแบบ\nโปรแกรมพื้นฐาน",
    id_34: "บล็อกเคลื่อนที่ (ขวา ซ้าย ขึ้น ลง กระโดด)",
    id_35: "บล็อกกระตุ้น",
    id_36: "บล็อกสิ้นสุด",
    id_37: "โมดูลที่ 2",
    id_38: "การออกแบบ\nรูปลักษณ์ของตัวละคร",
    id_39: "บล็อกฟีเจอร์ (พูด ซ่อนตัว แสดงตัว ขยายส่วน ย่อส่วน)",
    id_40: "โมดูลที่ 3",
    id_41: "การออกแบบ\nโปรแกรมตามเงื่อนไข",
    id_42: "การปรับค่าโปรแกรม",
    id_43: "บล็อกกระตุ้น (เริ่มทำงานเมื่อถูกแตะ เริ่มทำงานเมื่อถูกชน ส่งข้อความ เริ่มทำงานเมื่อได้รับข้อความ)",
    id_44: "โมดูลที่ 4",
    id_45: "การออกแบบ\nแอนิเมชันพร้อมเสียง",
    id_46: "บล็อกเสียง (เล่นเสียง อัดเสียง)",
    id_47: "บล็อกหมุน (หมุนซ้าย หมุนขวา)",
    id_48: "บล็อกวนซ้ำตลอดไป",
    id_49: "เขียนโปรแกรมหลายชุดใน 1 คาแรคเตอร์",
    id_50: "โมดูลที่ 5",
    id_51: "การออกแบบ\nแอนิเมชันซับซ้อน",
    id_52: "บล็อกคอนโทรล (รอ หยุด ปรับความเร็ว: ช้า)",
    id_53: "โมดูลที่ 6",
    id_54: "การออกแบบ\nโปรแกรมที่มีแพตเทิร์น",
    id_55: "บล็อกทำซ้ำ (loop)",
    id_56: "บล็อกปรับความเร็ว: เร็ว",
    id_57: "โมดูลที่ 7",
    id_58: "การออกแบบ\nโปรแกรมตามเงื่อนไขขั้นสูง",
    id_59: "บล็อกย้ายที่",
    id_60: "บล็อกเปลี่ยนคาแรกเตอร์",
    id_61: "บล็อกส่งข้อความ (หลายสี)",
    id_62: "บล็อกเริ่มทำงานเมื่อได้รับข้อความ (หลายสี)",
    id_63: "โมดูลที่ 8",
    id_64: "การออกแบบ\nโปรแกรมเชิงมิติ",
    id_65: "บล็อกพลิก (ซ้ายขวา บนล่าง)",
    id_66: "Miimo ทำให้การศึกษาสนุก\nสร้างนิสัยรักการเรียนรู้ให้เด็กทุกคน",
    id_67: "",
    id_81: "ติดต่อเรา",
    id_82: "Facebook",
    id_83: "Instagram",
    id_84: "Line Official",
    id_85: "LinkedIn",
    id_86: "Youtube",
    id_87: "อีเมล",
    id_88: "หน้าอื่น",
    id_89: "เกี่ยวกับเรา",
    id_90: "นโยบายความเป็นส่วนตัว",
    id_91: "ข้อกำหนดและเงื่อนไข",
    id_92: "ร่วมเป็นส่วนหนึ่งของเรา",
    id_93: "หน้าหลัก",
  },
  {
    id_1: "BELAJAR CODING LEWAT GAME",
    id_2: "Coding game bagi anak usia 4+ tahun ke atas untuk belajar sambil bermain dimana saja, kapan saja.",
    id_3: "SKILL UNTUK MASA DEPAN",
    id_4: "Berpetualang bersama Miimo untuk belajar Coding, melatih problem-solving, dan juga berpikir kritis!",
    id_5: "PENGALAMAN PENDIDIKAN INOVATIF",
    id_6: "Miimo adalah materi pendidikan terbaik untuk pengalaman belajar yang dipersonalisasi.",
    id_7: "PLAYGROUND DIGITAL YANG KREATIF",
    id_8: "Ciptakan animasi dan game dengan Code Block\nuntuk tampil di Miimo World Leaderboard.",
    id_9: "Download Sekarang",
    id_10: "REVOLUSIONER",
    id_11: "PENDEKATAN PEMBELAJARAN",
    id_12: "Hubungi Kami",
    id_13: "Cerita Utama",
    id_14: "Urus dan Rawat Miimo",
    id_15: "Dapatkan koin setelah menyelesaikan Coding Lesson. Gunakan koin ini untuk memberi makan dan bermain dengan Miimo. Rawat Miimo dan lihat dia tumbuh dan berevolusi.",
    id_16: "Mulai Belajar",
    id_17: "Dapatkan Koin",
    id_18: "Gunakan Koin",
    id_19: "Buat animasimu sendiri",
    id_20: "Sandbox",
    id_21: "Buat animasi freestyle dan game sesukamu di Sandbox Area menggunakan Code Blocks. Tunjukan skill coding-mu untuk tampil di Leaderboard.",
    id_22: "TUNJUKKAN PADA DUNIA",
    id_23: "BUAT ANIMASIMU SENDIRI",
    id_24: "MIIMO PROJECT",
    id_25: "Pelacakan dan Kontrol",
    id_26: "Parent Dashboard",
    id_27: "Lihat perkembangan, progres, project, serta prestasi yang dicapai oleh anak Anda. Tentukan limit waktu bermain dan monitor screen-time anak lewat Parent Dashboard yang dilengkapi passcode.",
    id_28: "Hanya tersedia di iPad",
    id_29: "CARA PALING MENYENANGKAN",
    id_30: "CARA BELAJAR CODING",
    id_31: "Pengalaman Belajar\nBerbentuk Game",
    id_32: "Module 1",
    id_33: "Desain Program Sederhana",
    id_34: "Motion Blocks (right, left, up, down, hop)",
    id_35: "Start Block",
    id_36: "End Block",
    id_37: "Module 2",
    id_38: "Desain Tampilan Karakter",
    id_39: "Feature Blocks (Say, Hide, Show, Grow, Shrink)",
    id_40: "Module 3",
    id_41: "Desain Program Conditional Sederhana",
    id_42: "Mengubah nilai di Block",
    id_43: "Trigger Blocks (start on tap, start on bump, send message, start on receive message)",
    id_44: "Module 4",
    id_45: "Desain Animasi dengan Suara",
    id_46: "Sound Blocks (play sound, record sound)",
    id_47: "Turn blocks (turn left, turn right)",
    id_48: "Repeat Forever Block",
    id_49: "Menambahkan beberapa program untuk 1 karakter",
    id_50: "Module 5",
    id_51: "Desain Animasi yang Kompleks",
    id_52: "Control Blocks (wait, stop, set speed: slow)",
    id_53: "Module 6",
    id_54: "Desain Program dengan Pola",
    id_55: "Loop Block",
    id_56: "Set Speed: fast",
    id_57: "Module 7",
    id_58: "Conditional Program Tingkat Lanjut",
    id_59: "Teleport Block",
    id_60: "Switch Character Block",
    id_61: "Send Message Block (warna lain)",
    id_62: "Start on Receive Message Blocks (warna lain)",
    id_63: "Module 8",
    id_64: "Perintah Tata Ruang Tingkat Lanjut",
    id_65: "Flip Block (vertical & horizontal)",
    id_66: "Miimo mengedukasi lewat game yang sangat menyenangkan sehingga setiap anak akan menikmati proses belajar.",
    id_67: "",
    id_68: "",
    id_69: "",
    id_70: "",
    id_71: "",
    id_72: "",
    id_73: "",
    id_74: "",
    id_75: "",
    id_76: "",
    id_77: "",
    id_78: "",
    id_79: "",
    id_80: "Kamu bisa mendesain code, mendesain rumah, memberi makan karakter, dan masih banyak lagi. Miimo memang game paling seru!",
    id_81: "Hubungi Kami",
    id_82: "Facebook",
    id_83: "Instagram",
    id_84: "Whatsapp",
    id_85: "LinkedIn",
    id_86: "Youtube",
    id_87: "Email",
    id_88: "Laman Lainnya",
    id_89: "Tentang Kami",
    id_90: "Kebijakan Privasi",
    id_91: "Syarat dan Ketentuan",
    id_92: "Kerja Bersama Kami",
    id_93: "Home",
  }
];