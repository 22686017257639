import { CustomSection, CustomHeader, CustomButton, Image } from "../../newPageStyle";


function Header()
{
  return(
    <>
      <CustomSection marginTop={'0vw'} mMarginTop={'-8px'} paddingBottom={'9vw'} mPaddingBottom={'25vw'}>
      </CustomSection>
    </>
  )
}

export default Header