import GlobalStyle from './GlobalStyles';
import PrivacyPage from './components/NewWebsite/PrivacyAndTemsPage/privacy';

function Privacy() {

  return (
    <div>
      <GlobalStyle/>
      <PrivacyPage></PrivacyPage>
    </div>
  );
}

export default Privacy;