import { CustomP, CustomP2, CustomSection, Image, ReviewSection } from "../../newPageStyle"
import starsImg from '../../../../assets/review/stars.png'

function ReviewItem(props) {
  return (
    <ReviewSection width={'25.3vw'} mWidth={'58vw'} height={'30.8vw'} mHeight={'74vw'} ignoreMargin={true}>
      <CustomP width={'23vw'} top={'0vw'} left={'5%'} fontSize={'1.7vw'}
        mWidth={'53vw'} mTop={'-1vw'} mLeft={'4%'} mFontSize={'4.5vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {props.name}</CustomP>
      <CustomP width={'23vw'} top={'2.4vw'} left={'5%'} fontSize={'1.4vw'}
        mWidth={'53vw'} mTop={'5.75vw'} mLeft={'4%'} mFontSize={'3.6vw'}
        fontWeight={300} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {props.occupation}</CustomP>
      <CustomP2 width={'23vw'} top={'10vw'} left={'5%'} fontSize={'1vw'}
        mWidth={'53vw'} mTop={'19vw'} mLeft={'4%'} mFontSize={'3vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {props.comment}</CustomP2>
      <Image src={starsImg} width={'13.2vw'} mWidth={'30vw'} top={'7vw'} mTop={'15.5vw'} left={'2.3%'} mLeft={'2.3%'}></Image>
      <Image src={props.imgSrc} width={'23vw'} mWidth={'53vw'} top={'16vw'} mTop={'40vw'} left={'4.2%'} mLeft={'4.2%'}></Image>
    </ReviewSection>
  )
}

export default ReviewItem