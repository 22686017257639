import AppInfoItem1 from "./appInfoItem1"
import AppInfoItem2 from "./appInfoItem2"
import Review from "./Review"

function AppInfo()
{
  return(
    <div>
      <AppInfoItem1></AppInfoItem1>
      <AppInfoItem2></AppInfoItem2>
      <Review></Review>
    </div>
  )
}

export default AppInfo