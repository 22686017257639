import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBJhIxwd7cPM6gw9AEtNyWHzoplow9ik2s",
    authDomain: "miimo-admin.firebaseapp.com",
    databaseURL: "https://miimo-admin-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "miimo-admin",
    storageBucket: "miimo-admin.appspot.com",
    messagingSenderId: "500042745795",
    appId: "1:500042745795:web:35e6ed530f5ce842069f76",
    measurementId: "G-S1HCQNXLBJ"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig)
const cloudFunctions = firebaseApp.functions('asia-southeast1')
if (process.env.NODE_ENV === 'development') {
    console.log('Running in development mode. Make sure to start the Firebase emulator...')
    firebaseApp.firestore().useEmulator('127.0.0.1', 8080)
    cloudFunctions.useEmulator('127.0.0.1', 5001)
}

export { firebaseApp, cloudFunctions }