import { CustomSection, CustomUl, CustomP, Image } from "../../newPageStyle"
import desktopImg from '../../../../assets/appInfo/item2/desktop.png'
import mobileImg from '../../../../assets/appInfo/item2/mobile.png'
import { TextData } from '../../../Data/InfoData'
import { useContext, useState } from "react";
import { UserContext } from "../../../..";

function AppInfoItem2() {
  const [index] = useContext(UserContext);
  const isIndo = index === 2;

  return (
    <CustomSection marginTop={'0vw'} mMarginTop={'-5vw'} paddingBottom={'400vw'} mPaddingBottom={'690vw'} zIndex={1}>
      <Image src={desktopImg} width={'100vw'} mWidth={'0vw'} top={'10vw'} left={'0vw'}></Image>
      <Image src={mobileImg} width={'0vw'} mWidth={'100vw'} mTop={'11.5vw'} mleft={'0vw'}></Image>

      <CustomP width={'100vw'} mWidth={'100vw'} left={'0%'} mLeft={'0%'} top={'21vw'} mTop={'34vw'} zIndex={10}
        fontWeight={600} fontSize={'2.55vw'} mFontSize={'5.1vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_31}
      </CustomP>

      <CustomP width={'30vw'} left={'24.5%'} top={'37.4vw'} fontSize={'1.8vw'}
        mWidth={'30vw'} mLeft={'21%'} mTop={'49.25vw'} mFontSize={'4.1vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#009EED'}>
        {TextData[index].id_32}
      </CustomP>
      <CustomP width={'30vw'} left={'18.5%'} top={'41vw'} fontSize={'2.5vw'}
        mWidth={'40vw'} mLeft={'8.5%'} mTop={'58vw'} mFontSize={'4.1vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {TextData[index].id_33}
      </CustomP>
      <CustomUl width={'30vw'} left={'20%'} top={'48.8vw'} fontSize={'1.2vw'}
        mWidth={'40vw'} mTop={'71vw'} mLeft={'11%'} mFontSize={'2.57vw'}
        color={'#21366C'} textAlign={'left'}>
        <li>{TextData[index].id_34}</li>
        <li>{TextData[index].id_35}</li>
        <li>{TextData[index].id_36}</li>
      </CustomUl>

      <CustomP width={'30vw'} left={'61%'} top={'78.25vw'} fontSize={'1.8vw'}
        mWidth={'30vw'} mLeft={'64.5%'} mTop={'130vw'} mFontSize={'4.1vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#009EED'}>
        {TextData[index].id_37}
      </CustomP>
      <CustomP width={'30vw'} left={'54.75%'} top={'82vw'} fontSize={'2.5vw'}
        mWidth={'50vw'} mLeft={'52%'} mTop={'138.5vw'} mFontSize={'4.1vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {TextData[index].id_38}
      </CustomP>
      <CustomUl width={'30vw'} left={'56.25%'} top={'90.25vw'} fontSize={'1.2vw'}
        mWidth={'46vw'} mTop={'151vw'} mLeft={'54.5%'} mFontSize={'2.57vw'}
        color={'#21366C'} textAlign={'left'}>
        <li>{TextData[index].id_39}</li>
      </CustomUl>

      <CustomP width={'30vw'} left={'24.5%'} top={'113.2vw'} fontSize={'1.8vw'}
        mWidth={'30vw'} mLeft={'21%'} mTop={'201.4vw'} mFontSize={'4.1vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#009EED'}>
        {TextData[index].id_40}
      </CustomP>
      <CustomP width={'30vw'} left={'18.5%'} top={'116.85vw'} fontSize={index !== 2 ? '2.5vw' : '2.4vw'}
        mWidth={'50vw'} mLeft={'8.5%'} mTop={'210vw'} mFontSize={'4.1vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {TextData[index].id_41}
      </CustomP>
      <CustomUl width={'25vw'} left={'20%'} top={'124.8vw'} fontSize={'1.2vw'}
        mWidth={'37vw'} mTop={'222.5vw'} mLeft={'11%'} mFontSize={'2.57vw'}
        color={'#21366C'} textAlign={'left'}>
        <li>{TextData[index].id_42}</li>
        <li>{TextData[index].id_43}</li>
      </CustomUl>

      <CustomP width={'30vw'} left={'62.5%'} top={'147.4vw'} fontSize={'1.8vw'}
        mWidth={'30vw'} mLeft={'64.5%'} mTop={'261.4vw'} mFontSize={'4.1vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#009EED'}>
        {TextData[index].id_44}
      </CustomP>
      <CustomP width={'30vw'} left={'56.25%'} top={'151vw'} fontSize={'2.5vw'}
        mWidth={'50vw'} mLeft={'52%'} mTop={'270.2vw'} mFontSize={'4.1vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {TextData[index].id_45}
      </CustomP>
      <CustomUl width={'30vw'} left={'57.75%'} top={'159vw'} fontSize={'1.2vw'}
        mWidth={'40vw'} mTop={'283vw'} mLeft={'54.5%'} mFontSize={'2.57vw'}
        color={'#21366C'} textAlign={'left'}>
        <li>{TextData[index].id_46}</li>
        <li>{TextData[index].id_47}</li>
        <li>{TextData[index].id_48}</li>
        <li>{TextData[index].id_49}</li>
      </CustomUl>

      <CustomP width={'30vw'} left={'24%'} top={'197.2vw'} fontSize={'1.8vw'}
        mWidth={'30vw'} mLeft={'21%'} mTop={'349.25vw'} mFontSize={'4.1vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#009EED'}>
        {TextData[index].id_50}
      </CustomP>
      <CustomP width={'30vw'} left={'17.5%'} top={'200.7vw'} fontSize={'2.5vw'}
        mWidth={'45vw'} mLeft={'8.5%'} mTop={'357.5vw'} mFontSize={'4.1vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {TextData[index].id_51}
      </CustomP>
      <CustomUl width={'30vw'} left={'19%'} top={'208.5vw'} fontSize={'1.2vw'}
        mWidth={'40vw'} mTop={'370.5vw'} mLeft={'11%'} mFontSize={'2.57vw'}
        color={'#21366C'} textAlign={'left'}>
        <li>{TextData[index].id_52}</li>
      </CustomUl>


      <CustomP width={'30vw'} left={'67.5%'} top={'237vw'} fontSize={'1.8vw'}
        mWidth={'30vw'} mLeft={'70%'} mTop={'420.5vw'} mFontSize={'4.1vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#009EED'}>
        {TextData[index].id_53}
      </CustomP>
      <CustomP width={'30vw'} left={'57.2%'} top={'240vw'} fontSize={'2.5vw'}
        mWidth={'50vw'} mLeft={'52%'} mTop={'428vw'} mFontSize={'4.1vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {TextData[index].id_54}
      </CustomP>
      <CustomUl width={'30vw'} left={'58.75%'} top={'248.25vw'} fontSize={'1.2vw'}
        mWidth={'46vw'} mTop={'440.5vw'} mLeft={'55%'} mFontSize={'2.57vw'}
        color={'#21366C'} textAlign={'left'}>
        <li>{TextData[index].id_55}</li>
        <li>{TextData[index].id_56}</li>
      </CustomUl>

      <CustomP width={'30vw'} left={'24.5%'} top={'275.6vw'} fontSize={'1.8vw'}
        mWidth={'30vw'} mLeft={'21%'} mTop={'478.75vw'} mFontSize={'4.1vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#009EED'}>
        {TextData[index].id_57}
      </CustomP>
      <CustomP width={'30vw'} left={'18.5%'} top={'278.75vw'} fontSize={'2.5vw'}
        mWidth={'50vw'} mLeft={'8.5%'} mTop={'488vw'} mFontSize={'4.1vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {TextData[index].id_58}
      </CustomP>
      <CustomUl width={'30vw'} left={'20%'} top={'286.2vw'} fontSize={'1.2vw'}
        mWidth={'40vw'} mTop={'501vw'} mLeft={'11%'} mFontSize={'2.57vw'}
        color={'#21366C'} textAlign={'left'}>
        <li>{TextData[index].id_59}</li>
        <li>{TextData[index].id_60}</li>
        <li>{TextData[index].id_61}</li>
        <li>{TextData[index].id_62}</li>
      </CustomUl>

      <CustomP width={'30vw'} left={'63.5%'} top={'320.7vw'} fontSize={'1.8vw'}
        mWidth={'30vw'} mLeft={'65%'} mTop={'573vw'} mFontSize={'4.1vw'}
        fontWeight={400} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#009EED'}>
        {TextData[index].id_63}
      </CustomP>
      <CustomP width={'30vw'} left={'56.8%'} top={'323.7vw'} fontSize={'2.5vw'}
        mWidth={'50vw'} mLeft={'52%'} mTop={'581.25vw'} mFontSize={'4.1vw'}
        fontWeight={600} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'} zIndex={10} color={'#21366C'}>
        {TextData[index].id_64}
      </CustomP>
      <CustomUl width={'30vw'} left={'58.3%'} top={'332vw'} fontSize={'1.2vw'}
        mWidth={'46vw'} mTop={'594.5vw'} mLeft={'54.5%'} mFontSize={'2.57vw'}
        color={'#21366C'} textAlign={'left'}>
        <li>{TextData[index].id_65}</li>
      </CustomUl>

      <CustomP width={'48vw'} mWidth={'70vw'} left={'10%'} mLeft={'15%'} top={'373vw'} mTop={'665vw'} zIndex={10}
        fontWeight={600} fontSize={'2.95vw'} mFontSize={isIndo ? '4.3vw' : '4.6vw'} lineHeight={'120%'} textAlign={'left'} mTextAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_66}
      </CustomP>

    </CustomSection>
  )
}

export default AppInfoItem2