import React, { useState, useEffect } from 'react'
import HeaderNavigator from '../NewMainPage/Headers/headerNavigator';
import PrivacyBody from './Bodies/privacy';
import PrivacyFooter from './Footers/privacyFooter';
import Header from './Headers/header';

function PrivacyPage()
{

  return (
    <body>
      <HeaderNavigator/>
      <Header></Header>
      <PrivacyBody></PrivacyBody>
      <PrivacyFooter></PrivacyFooter>
    </body>
  );
}

export default PrivacyPage