import img1 from '../../../../assets/review/review1.png'
import img2 from '../../../../assets/review/review2.png'
import img3 from '../../../../assets/review/review3.png'
import img4 from '../../../../assets/review/review4.png'
import img5 from '../../../../assets/review/review5.png'
import img6 from '../../../../assets/review/review6.png'
import img7 from '../../../../assets/review/review7.png'

export const reviewItems = [
  { name: "Poon & Punn", occupation: "Student", imgSrc: img1, comment: "Great educational app for kids. They really enjoyed learning to code with Miimo." },
  { name: "Buddy", occupation: "Student", imgSrc: img2, comment: "Buddy actually tried other coding apps before and said Miimo is the best one. He also introduced it to his friends in class." },
  { name: "Ai & Mai", occupation: "Student", imgSrc: img3, comment: "Exciting and fun coding game. I highly recommend it to all parents and kids." },
  { name: "Cream & Claire", occupation: "Student", imgSrc: img4, comment: "They love solving coding challenges in Miimo. They have been asking to play Miimo everyday." },
  { name: "Dindin", occupation: "Student", imgSrc: img5, comment: "Dindin could not stop. He said he has to learn, play, and feed Miimo so it grows up healthy. Highly recommended!" },
  { name: "Riw", occupation: "Student", imgSrc: img6, comment: "Miimo helps with logic. You have to plan and code in order to complete the challenges and tasks. Riw is happily learning how to code without having to force him at all." },
  { name: "Flora", occupation: "Student", imgSrc: img7, comment: "You can code, you can design your house, you can feed your character, and many more things. It's the best game!" },
]

export const reviewThItems = [
  { name: "น้องปุณณ์ & น้องปัณณ์", occupation: "นักเรียน", imgSrc: img1, comment: "น้องบอกว่าแอพดีมากๆ ชอบมากๆขอเล่นอยู่เรื่อยๆเลยค่ะ" },
  { name: "น้องบัดดี้", occupation: "นักเรียน", imgSrc: img2, comment: "น้องชอบมากค่ะ น้องเคยเรียน scratch แต่คิดว่า Miimo สนุกกว่า จะแนะนำเพื่อนๆให้ลองใช้นะคะ" },
  { name: "น้องอัย & น้องมัย", occupation: "นักเรียน", imgSrc: img3, comment: "แอพทำดี คู่ควรแก่การแนะนำ น้องๆชอบมากค่ะ " },
  { name: "น้องครีม & น้องแคลร์", occupation: "นักเรียน", imgSrc: img4, comment: "น้องๆชอบมากครับ เล่นต่อเรื่อยๆไปได้อีกหลายด่านแล้วครับ เมื่อวานน้องแคลร์เจอเพื่อน แนะนำ Miimo ให้เพื่อนเรียบร้อย" },
  { name: "น้องดินดิน", occupation: "นักเรียน", imgSrc: img5, comment: "น้องขอเล่นเรื่อยๆและบอกว่า ขอเล่นอีก Miimo จะได้โต ชอบมากค่ะ จะแนะนำให้เพื่อนรู้จักค่ะ" },
  { name: "น้องริว", occupation: "นักเรียน", imgSrc: img6, comment: "เกมทำให้เขาคิดแบบมี logic เหมือนหัดวางแผน คิดวิธีแก้ปัญหา หรือคิดวิธีการที่จะทำให้ถึงเป้าหมายครับ น้องชอบมากๆครับ" },
  { name: "น้อง Flora", occupation: "นักเรียน", imgSrc: img7, comment: "You can code, you can design your house, you can feed your character, and many more things. It's the best game!" },
]

export const reviewIndoItems = [
  { name: "Poon & Punn", occupation: "Student", imgSrc: img1, comment: "Great educational app for kids. They really enjoyed learning to code with Miimo." },
  { name: "Buddy", occupation: "Student", imgSrc: img2, comment: "Buddy actually tried other coding apps before and said Miimo is the best one. He also introduced it to his friends in class." },
  { name: "Ai & Mai", occupation: "Student", imgSrc: img3, comment: "Exciting and fun coding game. I highly recommend it to all parents and kids." },
  { name: "Cream & Claire", occupation: "Student", imgSrc: img4, comment: "They love solving coding challenges in Miimo. They have been asking to play Miimo everyday." },
  { name: "Dindin", occupation: "Student", imgSrc: img5, comment: "Dindin could not stop. He said he has to learn, play, and feed Miimo so it grows up healthy. Highly recommended!" },
  { name: "Riw", occupation: "Student", imgSrc: img6, comment: "Miimo helps with logic. You have to plan and code in order to complete the challenges and tasks. Riw is happily learning how to code without having to force him at all." },
  { name: "Flora", occupation: "Student", imgSrc: img7, comment: "Kamu bisa mendesain code, mendesain rumah, memberi makan karakter, dan masih banyak lagi. Miimo memang game paling seru!" },
]