import FooterItem1 from "./footerItem1"
import FooterItem2 from "./footerItem2"

function Footer()
{
  return(
    <div>
      <FooterItem1></FooterItem1>
      <FooterItem2></FooterItem2>
    </div>
  )
}

export default Footer