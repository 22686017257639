import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomButton, CustomP } from './components/NewWebsite/newPageStyle';

function LoginRedirectHandler() {
  const location = useLocation();
  console.log(location);
  const queryParams = new URLSearchParams(location.search);
  console.log(queryParams);
  const state = queryParams.get('state');
  const code = queryParams.get('code');
  const idToken = queryParams.get('id_token');
  const userJson = queryParams.get('user');
  const user = JSON.parse(userJson);

  useEffect(() => {
    const macOnlyContent = document.getElementById("macOnlyContent");
    if (navigator.userAgent.includes('Mac')) {
      macOnlyContent.style.display = "flex";
    }
  }, []);

  ForwardCodeToUnity(1091, code);
  ForwardCodeToUnity(1092, code);
  ForwardCodeToUnity(1093, code);
  ForwardCodeToUnity(1094, code);
  ForwardCodeToUnity(1095, code);

  return (
    <div style={styles.container}>
      <CustomP style={styles.header}>Please continue with</CustomP>
      <CustomP style={styles.header2}><b>MIIMO app</b></CustomP>
      <div id="macOnlyContent" style={styles.macOnlyContent}>
        <CustomButton style={styles.button} onClick={focusOrOpenMiimoApps}>Open the desktop app</CustomButton>
      </div>
    </div>
  );
}

function focusOrOpenMiimoApps() {
  focusOrOpenMiimoApp(1091);
  focusOrOpenMiimoApp(1092);
  focusOrOpenMiimoApp(1093);
  focusOrOpenMiimoApp(1094);
  focusOrOpenMiimoApp(1095);
}

function focusOrOpenMiimoApp(port) {
  fetch(`http://localhost:${port}/focusMiimo`, { method: 'POST' })
      .then(response => {
          if (!response.ok) {
              // Handle any error.
          }
      })
      .catch(error => {
        console.log('There was a problem with the fetch operation:', error.message);
      });
}

function ForwardCodeToUnity(port, code) {
  // Create a new URL for forwarding the code to Unity
  const unityURL = `http://localhost:${port}/?code=${code}`;
  fetch(unityURL)
    .then(response => {
      if (!response.ok) {
      }
      return response;
    })
    .then(response => {
      console.log('Success:', response);
    })
    .catch(error => {
      //console.log('There was a problem with the fetch operation:', error.message);
    });
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#80B1FF',
    margin: '-8px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',     // Vertically aligns the text to the middle
    justifyContent: 'center', // Horizontally aligns the text to the middle
    color: 'white',    
    marginBottom: '300px',
    fontSize: '42px',
  },
  header2: {
    display: 'flex',
    alignItems: 'center',     // Vertically aligns the text to the middle
    justifyContent: 'center', // Horizontally aligns the text to the middle
    color: 'white',    
    marginBottom: '200px',
    fontSize: '42px',
  },
  button: {
    width: '270px',
    height: '60px',
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '19px',
  },
  macOnlyContent: {
    display: 'none', // It starts as hidden and will be shown only for Macs
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default LoginRedirectHandler;