import { CustomSection, CustomP, Image, ImgButton } from "../../newPageStyle"
import { TextData } from '../../../Data/InfoData'
import desktopImg from '../../../../assets/header/item3/desktop.png'
import mobileImg from '../../../../assets/header/item3/mobile.png'
import appstoreButton from '../../../../assets/buttons/appstore.png'
import googleButton from '../../../../assets/buttons/googleplay.png'
import desktopButton from '../../../../assets/buttons/desktop.png'
import { useContext } from "react"
import { UserContext } from "../../../.."
import DesktopDownloadButton from "../Components/desktopDownloadButton"

function HeaderItem3() {
  const [index] = useContext(UserContext);

  return (
    <CustomSection paddingBottom={'47.3vw'} mPaddingBottom={'145vw'} ignoreMargin={true}>
      <Image src={desktopImg} width={'100vw'} mWidth={'0vw'} bottom={'0vw'} left={'-0.5vw'}></Image>
      <Image src={mobileImg} width={'0vw'} mWidth={'100vw'} mBottom={'0vw'} mleft={'0vw'}></Image>
      <ImgButton width={'16vw'} mWidth={'36vw'} height={'4vw'} mHeight={'9vw'} top={'25vw'} mTop={'75vw'} left={'33%'} mLeft={'14%'}
        fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={appstoreButton} onClick={() => window.open("https://apps.apple.com/th/app/miimo-coding-game-for-kids/id1644568803")}>
      </ImgButton>
      <ImgButton width={'16vw'} mWidth={'36vw'} height={'4vw'} mHeight={'9vw'} top={'25vw'} mTop={'75vw'} right={'33%'} mLeft={'52%'}
        fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={googleButton} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.miimoai.client")}>
      </ImgButton>
      <DesktopDownloadButton width={'16vw'} height={'4vw'} top={'30vw'} left={'42%'} fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={desktopButton} />
      <CustomP width={'40vw'} mWidth={'100vw'} left={'30%'} mLeft={'0%'} top={'6vw'} mTop={'28vw'}
        fontWeight={700} fontSize={'3.8vw'} mFontSize={'9vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#FFFFFF'} zIndex={3}>
        {TextData[index].id_3}
      </CustomP>
      <CustomP width={'40vw'} mWidth={'100vw'} left={'30%'} mLeft={'0%'} top={'6vw'} mTop={'28vw'}
        fontWeight={700} fontSize={'3.9vw'} mFontSize={'9.1vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#FFFFFF'} zIndex={2} addOn={'color: #FFE601;-webkit-text-stroke: 0.4vw #FCE40A;filter: blur(0.33vw);'} mAddOn={'color: #FFE601;-webkit-text-stroke: 1vw #FCE40A;filter: blur(0.5vw);'}>
        {TextData[index].id_3}
      </CustomP>
      <CustomP width={'40vw'} mWidth={'100vw'} left={'30%'} mLeft={'0%'} top={'18vw'} mTop={'57vw'}
        fontWeight={500} fontSize={'1.3vw'} mFontSize={'3.3vw'} lineHeight={'160%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#FFFFFF'}>
        {TextData[index].id_4}
      </CustomP>
    </CustomSection>
  )
}

export default HeaderItem3