import { CustomSection, CustomP, Image, ImgButton } from "../../newPageStyle"
import { TextData } from '../../../Data/InfoData'
import desktopImg from '../../../../assets/header/item2/desktop.png'
import mobileImg from '../../../../assets/header/item2/mobile.png'
import appstoreButton from '../../../../assets/buttons/appstore.png'
import googleButton from '../../../../assets/buttons/googleplay.png'
import desktopButton from '../../../../assets/buttons/desktop.png'
import { useContext } from "react"
import { UserContext } from "../../../.."
import DesktopDownloadButton from "../Components/desktopDownloadButton"

function HeaderItem2() {
  const [index] = useContext(UserContext);

  return (
    <div>
      <CustomSection paddingBottom={'47.3vw'} mPaddingBottom={'145vw'} ignoreMargin={true}>
        <Image src={desktopImg} width={'100vw'} mWidth={'0vw'} bottom={'0vw'} left={'0vw'}></Image>
        <Image src={mobileImg} width={'0vw'} mWidth={'100vw'} mBottom={'0vw'} mleft={'0vw'}></Image>
        <ImgButton width={'16vw'} mWidth={'36vw'} height={'4vw'} mHeight={'9vw'} top={'25vw'} mTop={'75vw'} left={'33%'} mLeft={'14%'}
          fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={appstoreButton} onClick={() => window.open("https://apps.apple.com/th/app/miimo-coding-game-for-kids/id1644568803")}>
        </ImgButton>
        <ImgButton width={'16vw'} mWidth={'36vw'} height={'4vw'} mHeight={'9vw'} top={'25vw'} mTop={'75vw'} right={'33%'} mLeft={'52%'}
          fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={googleButton} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.miimoai.client")}>
        </ImgButton>
        <DesktopDownloadButton width={'16vw'} height={'4vw'} top={'30vw'} left={'42%'}
          fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={desktopButton} />
      </CustomSection>
      <CustomP width={'60vw'} mWidth={'100vw'} left={'20%'} mLeft={'0%'} top={'6vw'} mTop={'33vw'}
        fontWeight={700} fontSize={'3.8vw'} mFontSize={'6.7vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#009EED'} addOn={'text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);'}>
        {TextData[index].id_5}
      </CustomP>
      <CustomP width={'40vw'} mWidth={'100vw'} left={'30%'} mLeft={'0%'} top={'18.2vw'} mTop={'57vw'}
        fontWeight={500} fontSize={'1.3vw'} mFontSize={'3.3vw'} lineHeight={'160%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_6}
      </CustomP>
    </div>
  )
}

export default HeaderItem2