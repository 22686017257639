import React, { useState, useEffect } from 'react'
import HeaderNavigator from '../NewMainPage/Headers/headerNavigator';
import TermBody from './Bodies/term';
import TermFooter from './Footers/termFooter';
import Header from './Headers/header';

function TermsPage()
{

  return (
    <body>
      <HeaderNavigator/>
      <Header></Header>
      <TermBody></TermBody>
      <TermFooter></TermFooter>
    </body>
  );
}

export default TermsPage