import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
* {  
     -webkit-backface-visibility:  hidden;
     -webkit-tap-highlight-color:  transparent;
     -webkit-perspective: 2000;
  }
  
  *::selection 
  {
      background-color:transparent;
  } 
  *::-moz-selection
  {
      background-color:transparent;
  }

  html *:not(svg) {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); /*only in IE10*/
}
`;
 
export default GlobalStyle;