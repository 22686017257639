import GlobalStyle from './GlobalStyles';
import TermsPage from './components/NewWebsite/PrivacyAndTemsPage/terms';

function Terms() {

  return (
    <div>
      <GlobalStyle/>
      <TermsPage/>
    </div>
  );
}

export default Terms;