import { cloudFunctions } from './firebase';

const getDownloadUrl = () => {
    const windowDownloadUrl = "https://miimo-admin.web.app/miimo-launcher-setup.exe?alt=media"
    const macOsDownloadUrl = "https://miimo-admin.web.app/miimo-launcher-setup.dmg?alt=media"
    const userAgent = navigator.userAgent;

    let platform = '';

    if (userAgent.indexOf('Win') !== -1) {
        platform = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
        platform = 'MacOS';
    }

    return platform === 'Windows' ? windowDownloadUrl : macOsDownloadUrl
}

const getIpAddress = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    } catch (error) {
        throw error;
    }
};

const checkDownload = async (ipAddress) => {
    try {
        const checkDownloadFunction = cloudFunctions.httpsCallable('checkDownload');
        const result = await checkDownloadFunction({ ipAddress });
        if (result.data.canDownload) {
            window.location.href = getDownloadUrl();
        } else {
            alert('You have already downloaded Miimo Launcher. Please wait 1 minute before downloading again.');
        }
    } catch (error) {
        throw error;
    }
};

export { getDownloadUrl, getIpAddress, checkDownload }