export const items = [
    { itemText: "HOME", itemLink: "/" },
]

export const thItems = [
    { itemText: "หน้าหลัก", itemLink: "/" },
]

export const indoItems = [
    { itemText: "HOME", itemLink: "/" },
]