import { CustomButton2, CustomP, CustomP2, CustomSection, Image, ImgButton } from "../../newPageStyle"
import bg from '../../../../assets/appInfo/item1//bg.png'
import desktopImg from '../../../../assets/appInfo/item1/desktop.png'
import mobileImg from '../../../../assets/appInfo/item1/mobile.png'
import ReactPlayer from 'react-player/youtube'
import { TextData } from '../../../Data/InfoData'
import { useMediaQuery } from "react-responsive"
import { useContext } from "react"
import yellowButton from '../../../../assets/buttons/yellow.png'
import ipadImg from '../../../../assets/appInfo/item1/ipadframe.png'
import appstoreButton from '../../../../assets/buttons/appstore.png'
import googleButton from '../../../../assets/buttons/googleplay.png'
import { UserContext } from "../../../.."
import desktopButton from '../../../../assets/buttons/desktop.png'
import DesktopDownloadButton from "../Components/desktopDownloadButton"

function AppInfoItem1() {
  const [index] = useContext(UserContext);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isIndo = index === 2;
  const chatUrl = isIndo || index === 0 ? "https://wa.me/message/3X2RYQ74TGE4K1" : "https://lin.ee/nez2wdC";

  return (
    <CustomSection url={`url(${bg})`} marginTop={'-10.6vw'} mMarginTop={'-15vw'} paddingBottom={'350vw'} mPaddingBottom={'455vw'} zIndex={10}>

      <CustomP width={'100vw'} mWidth={'100vw'} left={'50%'} top={'21vw'} mTop={'35vw'} middleT={true} zIndex={10}
        fontWeight={700} fontSize={'4vw'} mFontSize={'8.2vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#FFFFFF'}>
        {TextData[index].id_10}
      </CustomP>
      <CustomP width={'100vw'} mWidth={'100vw'} left={'50%'} top={'26.5vw'} mTop={'46vw'} middleT={true} zIndex={10}
        fontWeight={700} fontSize={'2.3vw'} mFontSize={'4vw'} lineHeight={'160%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#FFFFFF'}>
        {TextData[index].id_11}
      </CustomP>

      <Image src={desktopImg} width={'100vw'} mWidth={'0vw'} top={'10vw'} left={'0vw'} zIndex={10}></Image>
      <Image src={mobileImg} width={'0vw'} mWidth={'100vw'} mTop={'15vw'} mleft={'0vw'} zIndex={10}></Image>

      <Image src={ipadImg} width={'52.2vw'} top={'32.6vw'} height={'37.5vw'} left={'23.9%'}
        mWidth={'72.8vw'} mTop={'60.8vw'} mLeft={'13.6%'} zIndex={10} ></Image>
      <ReactPlayer
        className="react-player"
        url={index === 2 ? 'https://youtu.be/H9FMxEsLI6Y' : index === 1 ? 'https://youtu.be/saVKFvxQ7HQ' : ' https://youtu.be/CjFzB7plRPc'}
        width={isMobile ? '62vw' : '47vw'}
        height={isMobile ? '44vw' : '32vw'}
      />


      <CustomButton2 width={'18vw'} mWidth={'34vw'} height={'5.5vw'} mHeight={'11vw'}
        top={'79vw'} mTop={'125vw'} left={'50%'} middleT={true}
        fontWeight={500} fontSize={'2.4vw'} mFontSize={'4vw'} img={yellowButton} onClick={() => window.open(chatUrl)}>
        {TextData[index].id_12}
      </CustomButton2>

      <CustomP width={'30vw'} mWidth={'30vw'} left={'65%'} top={'114vw'} mTop={'155vw'} zIndex={10}
        fontWeight={600} fontSize={'1.5vw'} mFontSize={'2.5vw'} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#009EED'}>
        {TextData[index].id_13}
      </CustomP>
      <CustomP width={'30vw'} mWidth={'25vw'} left={'65%'} top={'115vw'} mTop={'157vw'} zIndex={10}
        fontWeight={600} fontSize={isIndo ? '2.5vw' : '2.7vw'} mFontSize={isIndo ? '3.7vw' : '4.4vw'} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_14}
      </CustomP>
      <CustomP2 width={'30vw'} mWidth={'30vw'} left={'65.2%'} top={'121vw'} mTop={'171vw'} zIndex={10}
        fontWeight={400} fontSize={'1.25vw'} mFontSize={'2.25vw'} lineHeight={'130%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_15}
      </CustomP2>

      <CustomP width={'15vw'} mWidth={'25vw'} left={'11%'} mLeft={'5%'} top={'113.5vw'} mTop={'164.5vw'} zIndex={10}
        fontWeight={600} fontSize={'1.5vw'} mFontSize={'2vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_16}
      </CustomP>
      <CustomP width={'15vw'} mWidth={'25vw'} left={'43%'} mLeft={'36.5%'} top={'123.5vw'} mTop={'175vw'} zIndex={10}
        fontWeight={600} fontSize={'1.5vw'} mFontSize={'2vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_17}
      </CustomP>
      <CustomP width={'15vw'} mWidth={'25vw'} left={'5%'} mLeft={'0%'} top={'144.5vw'} mTop={'194vw'} zIndex={10}
        fontWeight={600} fontSize={'1.5vw'} mFontSize={'2vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_18}
      </CustomP>

      <CustomP width={'30vw'} mWidth={'40vw'} left={'7%'} mLeft={'4.5%'} top={'174vw'} mTop={'224vw'} zIndex={10}
        fontWeight={600} fontSize={'1.5vw'} mFontSize={'2.5vw'} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#009EED'}>
        {TextData[index].id_19}
      </CustomP>
      <CustomP width={'30vw'} mWidth={'30vw'} left={'7%'} mLeft={'4.5%'} top={'175vw'} mTop={'226vw'} zIndex={10}
        fontWeight={600} fontSize={'2.7vw'} mFontSize={'4.4vw'} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_20}
      </CustomP>
      <CustomP2 width={'32vw'} mWidth={'32vw'} left={'7%'} mLeft={'4.5%'} top={'181vw'} mTop={'235vw'} zIndex={10}
        fontWeight={400} fontSize={'1.25vw'} mFontSize={'2.25vw'} lineHeight={'130%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_21}
      </CustomP2>

      <CustomP width={'25vw'} mWidth={'25vw'} left={'38.4%'} mLeft={'45.5%'} top={'166.4vw'} mTop={'226.25vw'} zIndex={10}
        fontWeight={600} fontSize={'1.25vw'} mFontSize={'1.35vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_22}
      </CustomP>
      <CustomP width={'30vw'} mWidth={'35vw'} left={'58.5%'} mLeft={'64.75%'} top={'157.5vw'} mTop={'216.5vw'} zIndex={10}
        fontWeight={600} fontSize={'1.25vw'} mFontSize={'1.35vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_23}
      </CustomP>
      <CustomP width={'30vw'} mWidth={'25vw'} left={'80%'} mLeft={'5%'} top={'162.5vw'} mTop={'194vw'} zIndex={10}
        fontWeight={600} fontSize={'1.2vw'} mFontSize={'0vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_24}
      </CustomP>

      <CustomP width={'30vw'} mWidth={'30vw'} left={'65%'} top={'228vw'} mTop={'277vw'} zIndex={10}
        fontWeight={600} fontSize={'1.5vw'} mFontSize={'2.5vw'} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#009EED'}>
        {TextData[index].id_25}
      </CustomP>
      <CustomP width={'30vw'} mWidth={'30vw'} left={'65%'} top={'229vw'} mTop={'279vw'} zIndex={10}
        fontWeight={600} fontSize={'2.7vw'} mFontSize={'4.4vw'} lineHeight={'120%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_26}
      </CustomP>
      <CustomP2 width={'30vw'} mWidth={'32vw'} left={'65.2%'} top={'235vw'} mTop={'293vw'} zIndex={10}
        fontWeight={400} fontSize={'1.25vw'} mFontSize={'2.25vw'} lineHeight={'130%'} textAlign={'left'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_27}
      </CustomP2>

      <CustomP width={'48vw'} mWidth={'100vw'} left={'-2%'} mLeft={'0%'} top={'285vw'} mTop={isMobile && isIndo ? '324vw' : '335vw'} zIndex={10}
        fontWeight={600} fontSize={'1.4vw'} mFontSize={'3.7vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#009EED'}>
        {TextData[index].id_28}
      </CustomP>
      <CustomP width={'50vw'} mWidth={'100vw'} left={'-2%'} mLeft={'0%'} top={'286.5vw'} mTop={isMobile && isIndo ? '326vw' : '337vw'} zIndex={10}
        fontWeight={700} fontSize={isIndo ? '2.8vw' : '3.6vw'} mFontSize={'8.2vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_29}
      </CustomP>
      <CustomP width={'50vw'} mWidth={'100vw'} left={'-2%'} mLeft={'0%'} top={'291vw'} mTop={'349vw'} zIndex={10}
        fontWeight={700} fontSize={isIndo ? '2.6vw' : '2.8vw'} mFontSize={'5.6vw'} lineHeight={'130%'} textAlign={'center'} letterSpacing={'0.05vw'}
        color={'#21366C'}>
        {TextData[index].id_30}
      </CustomP>

      <ImgButton width={'16vw'} mWidth={'36vw'} height={'4vw'} mHeight={'9vw'} top={'299vw'} mTop={'368vw'} left={'6.2%'} mLeft={'12.5%'}
        fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={appstoreButton} onClick={() => window.open("https://apps.apple.com/th/app/miimo-coding-game-for-kids/id1644568803")}>
      </ImgButton>
      <ImgButton width={'16vw'} mWidth={'36vw'} height={'4vw'} mHeight={'9vw'} top={'299vw'} mTop={'368vw'} left={'23.7%'} mLeft={'52.5%'}
        fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={googleButton} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.miimoai.client")}>
      </ImgButton>
      <DesktopDownloadButton width={'16vw'} height={'4vw'} top={'304vw'} left={'15%'}
        fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={desktopButton} />

    </CustomSection>
  )
}

export default AppInfoItem1