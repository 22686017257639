import React, { useContext, useState } from "react";

import { indoItems, items, thItems } from "./MenuItems";
import "./Menu.css"
import { CustomP, ImageClickable, Image } from "../newPageStyle";

import LogoImg from "../../../assets/Miimo-Logo.png"
import ENLangIcon from '../../../assets/navigator/en-lang.png'
import THLangIcon from '../../../assets/navigator/th-lang.png'
import IndoLangIcon from '../../../assets/navigator/indo-lang.png'
import CloseIcon from '../../../assets/navigator/x.png'
import { UserContext } from "../../..";
import { useHistory } from "react-router-dom";

function Menu({ isOpen, onChange }) {

  const [index, setIndex] = useContext(UserContext);
  const history = useHistory();
  const openAboutUs = () => history.push('/about-us');
  return (
    <div className={`Menu ${isOpen && "open"}`}>
      <ImageClickable src={CloseIcon} mTop={'3%'} mLeft={'7%'} width={'0vw'} mWidth={'7.5vw'} zIndex={10} onClick={() => onChange(false)}></ImageClickable>
      <Image src={LogoImg} top={'3vw'} mTop={'9vw'} left={'50vw'} width={'0vw'} mWidth={'22vw'} middleT={true} zIndex={10}></Image>
      <div className="Menu-items">
        {index === 0 && items.map((item, index) => (
          <a key={index} onClick={() => { history.push(item.itemLink); onChange(false); }}>
            {item.itemText}
          </a>
        ))}

        {index === 1 && thItems.map((item, index) => (
          <a key={index} onClick={() => { history.push(item.itemLink); onChange(false); }}>
            {item.itemText}
          </a>
        ))}

        {index === 2 && indoItems.map((item, index) => (
          <a key={index} onClick={() => { history.push(item.itemLink); onChange(false); }}>
            {item.itemText}
          </a>
        ))}
      </div>
      <ImageClickable src={THLangIcon} mBottom={'20%'} mLeft={'13vw'} width={'0vw'} mWidth={'15vw'} zIndex={10} onClick={() => { setIndex(1); onChange(false); }}></ImageClickable>
      <CustomP mBottom={'20.5%'} mLeft={'32vw'} width={'0.5vw'} mWidth={'2vw'}
        fontSize={'0vw'} mFontSize={'9vw'} fontWeight={'200'} color={'#FFFFFF'} zIndex={10}
        bottomT={true}>
        |
      </CustomP>
      <ImageClickable src={ENLangIcon} mBottom={'20%'} mLeft={'36vw'} width={'0vw'} mWidth={'15vw'} zIndex={10} onClick={() => { setIndex(0); onChange(false); }}></ImageClickable>
      <CustomP mBottom={'20.5%'} mLeft={'55vw'} width={'0.5vw'} mWidth={'2vw'}
        fontSize={'0vw'} mFontSize={'9vw'} fontWeight={'200'} color={'#FFFFFF'} zIndex={10}
        bottomT={true}>
        |
      </CustomP>
      <ImageClickable src={IndoLangIcon} mBottom={'20%'} mLeft={'59vw'} width={'0vw'} mWidth={'30vw'} zIndex={10} onClick={() => { setIndex(2); onChange(false); }}></ImageClickable>
    </div>
  );
}

export default Menu;
