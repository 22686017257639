import LogoImg from "../../../../assets/Miimo-Logo.png"
import FbImg from '../../../../assets/navigator/fb.png'
import IgImg from '../../../../assets/navigator/ig.png'
import LineImg from '../../../../assets/navigator/line.png'
import WhatAppImg from '../../../../assets/navigator/what-app.png'
import ENLangIcon from '../../../../assets/navigator/en-lang-black.png'
import THLangIcon from '../../../../assets/navigator/th-lang-black.png'
import IndoLangIcon from '../../../../assets/navigator/indo-lang-black.png'
import menuIcon from '../../../../assets/navigator/menu.png'
import { Image, ImageClickable, CustomP, TransButton } from "../../newPageStyle";
import { CustomSection, CustomHeader, CustomButton } from "../../newPageStyle";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, createContext, useContext } from 'react'
import Menu from "../../MenuPage/Menu"
import { useMediaQuery } from 'react-responsive';
import { UserContext } from "../../../.."
import { TextData } from '../../../Data/InfoData'

function HeaderNavigator() {

  const history = useHistory();
  const openAboutUs = () => history.push('/about-us');
  const openHome = () => history.push('/');
  const [index, setIndex] = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isIndoOrEng = index === 2 || index === 0;
  const chatUrl = isIndoOrEng ? "https://wa.me/message/3X2RYQ74TGE4K1" : "https://lin.ee/nez2wdC";

  const useDisableBodyScroll = (open) => {
    useEffect(() => {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }, [open]);
  };

  useDisableBodyScroll(isOpen && isMobile);

  return (
    <>
      <Menu isOpen={isOpen && isMobile} onChange={setIsOpen}></Menu>
      <ImageClickable src={menuIcon} mTop={'6vw'} mLeft={'0.5vw'} width={'0vw'} mWidth={'6vw'} zIndex={10} onClick={setIsOpen}></ImageClickable>
      <TransButton top={'2.2vw'} left={'1.5vw'} width={'7vw'} mWidth={'0vw'} mFontSize={'0vw'} onClick={openHome} color={'#21366C'} >
        {TextData[index].id_93}
      </TransButton>
      <Image src={LogoImg} top={'3vw'} mTop={'9vw'} left={'49.5%'} mLeft={'50%'} width={'8vw'} mWidth={'22vw'} middleT={true} zIndex={10}></Image>

      <ImageClickable src={IgImg} top={'2vw'} mTop={'6vw'} right={'24.5vw'} mRight={'10vw'} width={'2.5vw'} mWidth={'5.3vw'} zIndex={10}
        onClick={() => window.open(isIndoOrEng ? "https://www.instagram.com/miimoid" : "https://www.instagram.com/miimo.ai")}></ImageClickable>
      <ImageClickable
        src={isIndoOrEng ? WhatAppImg : LineImg}
        top={'2vw'}
        mTop={'6vw'}
        right={'21vw'}
        mRight={'3vw'}
        width={'2.5vw'}
        mWidth={'5.3vw'}
        zIndex={10}
        onClick={() => window.open(chatUrl)}
      />
      <ImageClickable src={THLangIcon} top={'2.5vw'} right={'15vw'} width={'3.75vw'} mWidth={'0vw'} zIndex={10} onClick={() => setIndex(1)}></ImageClickable>
      <CustomP top={'0.45vw'} right={'13.75vw'} width={'0.5vw'} mWidth={'0vw'} fontSize={'1.64vw'} mFontSize={'0vw'} fontWeight={'300'} color={'#21366C'} zIndex={10}>
        |
      </CustomP>
      <ImageClickable src={ENLangIcon} top={'2.5vw'} right={'9.25vw'} width={'3.75vw'} mWidth={'0vw'} zIndex={10} onClick={() => setIndex(0)}></ImageClickable>
      <CustomP top={'0.45vw'} right={'8vw'} width={'0.5vw'} mWidth={'0vw'} fontSize={'1.64vw'} mFontSize={'0vw'} fontWeight={'300'} color={'#21366C'} zIndex={10}>
        |
      </CustomP>
      <ImageClickable src={IndoLangIcon} top={'2.5vw'} right={'0.5vw'} width={'6.75vw'} mWidth={'0vw'} zIndex={10} onClick={() => setIndex(2)}></ImageClickable>
    </>
  )
}

export default HeaderNavigator