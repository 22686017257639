import React, { createContext, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import AboutUs from './AboutUs';
import { Route, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom';
import Privacy from './Privacy';
import Terms from './Terms';
import LoginRedirectHandler from './LoginRedirectHandler';
import './firebase';

export const UserContext = createContext()

function MyProvider({ children }) {
  const [state, setState] = useState(0);
  return <UserContext.Provider value={[state, setState]}>{children}</UserContext.Provider>;
}

ReactDOM.render(
  <MyProvider>
    <React.StrictMode>
      <Router>
        <Switch>
          <Route exact path="/privacy-policy">
            <Privacy></Privacy>
          </Route>
          <Route exact path="/terms-and-conditions">
            <Terms></Terms>
          </Route>
          <Route exact path="/">
            <Main></Main>
          </Route>
          <Route exact path='/freeTrial' component={DeeplinkRedirect} />
          <Route path="/redirect" component={LoginRedirectHandler} />
          <Route exact path='/admin' component={() => {
            window.location.href = 'https://miimo-ai.web.app';
            return null;
          }} />
        </Switch>
      </Router>
    </React.StrictMode>
  </MyProvider>,
  document.getElementById('root')
);

function DeeplinkRedirect() {
  React.useEffect(() => {
    // Attempt to open the custom URL
    window.location.href = 'miimoai://dl?freeTrialClass';

    // Wait for 3 seconds to check if the user is still on the page
    setTimeout(() => {
      // Check if the user is still on the page
      if (document.hasFocus()) {
        // Detect the user's device type
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
          // Redirect Android users to Google Play Store
          window.location.href = 'https://play.google.com/store/apps/details?id=com.miimoai.client';
        } else {
          // Redirect iOS users to Apple App Store
          window.location.href = 'https://apps.apple.com/app/1644568803';
        }
      }
    }, 2000);
  }, []);

  return <div>Redirecting...</div>;
}