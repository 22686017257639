import { TransButton, CustomP, CustomSection, Image, ImageClickable } from "../../newPageStyle"
import desktopImg from '../../../../assets/footer/item2/desktop.png'
import mobileImg from '../../../../assets/footer/item2/mobile.png'
import appleImg from '../../../../assets/footer/apple.png'
import googleImg from '../../../../assets/footer/google.png'
import goToTopImg from '../../../../assets/footer/go-to-top.png'
import { useHistory } from "react-router-dom"
import { useContext } from "react"
import { UserContext } from "../../../.."
import { TextData } from '../../../Data/InfoData'
import { useMediaQuery } from 'react-responsive';
import desktopFooterImg from '../../../../assets/footer/desktop.png'
import { checkDownload, getIpAddress } from '../../../../desktopDownload'

function FooterItem2() {
  const [index] = useContext(UserContext)

  const scrollToTopSmooth = async () => {
    window.scrollTo({
      top: 0,
    });
  }

  const scrollToTop = async () => {
    window.scrollTo({
      top: 0,
    });
  }

  const history = useHistory();
  const openHome = () => history.push('/');
  const openAboutUs = () => history.push('/about-us');
  const openPrivacy = () => history.push('/privacy-policy');
  const openTerms = () => history.push('/terms-and-conditions');
  const isIndo = index === 2;
  const chatUrl = isIndo ? "https://wa.me/message/3X2RYQ74TGE4K1" : "https://lin.ee/nez2wdC";

  const isDesktop = useMediaQuery({ query: `(min-width: 760px)` });

  return (
    <CustomSection marginTop={'-32vw'} mMarginTop={'-37.2vw'} paddingBottom={'45vw'} marginBottom={'-8px'} mPaddingBottom={'153vw'}>
      <Image src={desktopImg} width={'100vw'} mWidth={'0vw'} top={'0vw'} left={'0vw'}></Image>
      <Image src={mobileImg} width={'0vw'} mWidth={'100vw'} mTop={'15vw'} mleft={'0vw'}></Image>

      {isDesktop ?
        <div className="HorizontalLayout" style={{
          width: 795, height: 83, position: 'absolute', bottom: "4.5%", left: "43.5%",
          justifyContent: 'flex-start', alignItems: 'flex-end', gap: 18, display: 'inline-flex'
        }}>
          <ImageClickable src={appleImg} width={'9.7vw'} position={'relative'} onClick={() => window.open("https://apps.apple.com/th/app/miimo-coding-game-for-kids/id1644568803")} />
          <ImageClickable src={googleImg} width={'10.6vw'} position={'relative'} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.miimoai.client")} />
          <ImageClickable src={desktopFooterImg} width={'10.6vw'} position={'relative'} onClick={async () => checkDownload(await getIpAddress())} />
        </div>
        :
        <>
          <ImageClickable src={appleImg} width={'9.7vw'} right={'21.5vw'} bottom={'2vw'}
            mWidth={'31.5vw'} mLeft={'14vw'} mBottom={'15vw'}
            onClick={() => window.open("https://apps.apple.com/th/app/miimo-coding-game-for-kids/id1644568803")} />
          <ImageClickable src={googleImg} width={'10.6vw'} right={'10vw'} bottom={'2vw'}
            mWidth={'35.5vw'} mLeft={'50vw'} mBottom={'15vw'}
            onClick={() => window.open("https://play.google.com/store/apps/details?id=com.miimoai.client")} />
        </>
      }

      <ImageClickable
        src={goToTopImg} width={'5.7vw'} right={'2vw'} bottom={'2vw'}
        mWidth={'12.4vw'} mRight={'5vw'} mTop={'60vw'}
        onClick={scrollToTopSmooth}
      />

      <CustomP width={'18vw'} mWidth={'30vw'} left={'7.9vw'} mLeft={'7.9vw'} top={'25vw'} mTop={'50vw'}
        fontWeight={400} fontSize={'1.4vw'} mFontSize={'4.1vw'} letterSpacing={'0.03vw'} textAlign={'left'} color={'#FFFFFF'}>
        {TextData[index].id_88}
      </CustomP >
      <TransButton width={'18vw'} mWidth={'0vw'} top={'29vw'} left={'7.5vw'} mTop={'60vw'} mLeft={'8vw'}
        fontWeight={400} fontSize={'1.4vw'} mFontSize={'0vw'} textAlign={'left'} onClick={() => { openHome(); scrollToTop(); }} >
        {TextData[index].id_93}
      </TransButton>
      <TransButton width={'18vw'} mWidth={'40vw'} top={'31vw'} left={'7.5vw'} mTop={'63vw'} mLeft={'8vw'}
        fontWeight={400} fontSize={'1.4vw'} mFontSize={'4vw'} textAlign={'left'} onClick={() => { openPrivacy(); scrollToTop(); }} >
        {TextData[index].id_90}
      </TransButton>
      <TransButton width={'18vw'} mWidth={'50vw'} top={'33vw'} left={'7.5vw'} mTop={'70vw'} mLeft={'8vw'}
        fontWeight={400} fontSize={'1.4vw'} mFontSize={'4.1vw'} textAlign={'left'} onClick={() => { openTerms(); scrollToTop(); }} >
        {TextData[index].id_91}
      </TransButton>
      <TransButton width={'18vw'} mWidth={'30vw'} top={'35vw'} left={'7.5vw'} mTop={'77vw'} mLeft={'8vw'}
        fontWeight={400} fontSize={'1.4vw'} mFontSize={'4.1vw'} textAlign={'left'}
        onClick={() => window.open("https://able-aletopelta-1af.notion.site/Join-Our-Team-69713585f7f34c5d9aa024ee0fcd4041")}>
        {TextData[index].id_92}
      </TransButton>

      <CustomP width={'20vw'} mWidth={'100vw'} left={'7.8vw'} mLeft={'0vw'} bottom={'0vw'} mBottom={'2vw'}
        fontWeight={200} fontSize={'1vw'} mFontSize={'3.5vw'} letterSpacing={'0.03vw'}
        textAlign={'left'} mTextAlign={'center'} color={'#FFFFFF'}>
        © 2023 Miimo AI Co., Ltd.
      </CustomP >


      <CustomP width={'20vw'} mWidth={'84vw'} left={'25.8vw'} mLeft={'8vw'} bottom={'2.1vw'} mBottom={'27vw'}
        fontWeight={200} fontSize={'1vw'} mFontSize={'2.7vw'} letterSpacing={'0.03vw'}
        textAlign={'left'} mTextAlign={'left'} color={'#FFFFFF'}>
        PT Miimo Teknologi Indonesia
        <br></br><br></br>GEDUNG AD PREMIER OFFICE PARK LT. 9, JL. TB. SIMATUPANG NO. 5, Desa/Kelurahan Ragunan, <br></br>Kec. Pasar Minggu, Kota Adm. Jakarta Selatan, Provinsi DKI Jakarta
        <br></br><br></br>08125331988
        <br></br>team@miimo.ai
      </CustomP >
    </CustomSection>
  )
}

export default FooterItem2