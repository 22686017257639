import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ImgButton } from "../../newPageStyle"
import { checkDownload, getIpAddress } from '../../../../desktopDownload';

function DesktopDownloadButton(props) {
    const isDesktop = useMediaQuery({ query: `(min-width: 760px)` });

    return (
        <>
            {isDesktop ?
                <ImgButton {...props} onClick={async () => checkDownload(await getIpAddress())}>
                </ImgButton > : null
            }
        </>
    );
}

export default DesktopDownloadButton;