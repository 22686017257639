import { CustomSection, CustomHeader, CustomButton, Image } from "../../newPageStyle";
import desktopImg from '../../../../assets/footer/privacy-terms/desktop.png'
import mobileImg from '../../../../assets/footer/privacy-terms/mobile.png'
import FooterItem2 from "../../NewMainPage/Footers/footerItem2";

function PrivacyFooter()
{
  return(
    <div>
      <CustomSection  marginTop={'-45vw'} mMarginTop={'-150vw'} paddingBottom={'74vw'} mPaddingBottom={'199vw'}>
        <Image src={desktopImg} width={'100vw'} mWidth={'0vw'} top={'0.4vw'} left={'0vw'} zIndex={0}></Image>
        <Image src={mobileImg} width={'0vw'} mWidth={'100vw'} mTop={'55.5vw'} mleft={'0vw'} zIndex={-20}></Image>
      </CustomSection>
      <FooterItem2></FooterItem2>
    </div>
  )
}

export default PrivacyFooter