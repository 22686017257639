import Header from "./Headers/header";
import AppInfo from "./AppInfo/appInfo";
import Footer from "./Footers/footer";
import Menu from "../MenuPage/Menu";
import React, { useState, useEffect, createco } from 'react'
import HeaderNavigator from "./Headers/headerNavigator";

function NewMainPage()
{

  return (
    <body>
      <HeaderNavigator></HeaderNavigator>
      <Header></Header>
      <AppInfo></AppInfo>
      <Footer></Footer>
    </body>
  );
}

export default NewMainPage