import styled from "styled-components";

export const CustomSection = styled.section`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left:  ${props => props.ignoreMargin ? '0px' : '-16px'};
  margin-right: ${props => props.ignoreMargin ? '0px' : '-16px'};
  padding: 0; /* remove any pre-existing padding, just in case */
  padding-bottom: ${props => props.paddingBottom};
  background: ${props => props.url};
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position:relative;
  z-index: ${props => props.zIndex} ;
  overflow:hidden;

  @media screen and (max-width: 760px) 
  {
    background-size: cover;
    margin-top: ${props => props.mMarginTop};
    padding-bottom: ${props => props.mPaddingBottom};
   // border-style: dotted;
  }
  //border-style: dotted;
`

export const CustomHeader = styled.section`
  margin-top: -8px;
  margin-left:  ${props => props.ignoreMargin ? '0px' : '-16px'};
  margin-right: ${props => props.ignoreMargin ? '0px' : '-16px'};

  @media screen and (max-width: 760px) 
  {
    background-size: cover;
  }
`

export const CustomP = styled.p`
  white-space: pre-wrap;
  position: absolute;
  height: auto;
  height: ${props => props.height};
  width: ${props => props.width};
  top: ${props => props.top};
  left:  ${props => props.left};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  font-family: 'Poppins', 'Prompt', sans-serif;
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props => props.letterSpacing};
  z-index: 50;
  z-index: ${props => props.zIndex};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  ${props => props.middleT ? 'transform:translate(-50%, -50%);' : ''}
  ${props => props.bottomT ? 'transform:translate(-50%, 100%);' : ''}
  //border-style: dotted;

  ${props => props.addOn};

  bold {
    font-weight: 700;
    font-style: bold;
  }

   @media screen and (max-width: 760px) 
  {
    height: ${props => props.mHeight};
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    font-size: ${props => props.mFontSize};
    text-align: ${props => props.mTextAlign};
    ${props => props.mAddOn};
  }
`;

export const CustomP2 = styled.p`
  position: absolute;
  white-space: pre-wrap;
  height: ${props => props.height};
  width: ${props => props.width};
  top: ${props => props.top};
  left:  ${props => props.left};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  font-family: 'Mulish', 'Prompt', sans-serif;
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props => props.letterSpacing};
  z-index: ${props => props.zIndex};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  vertical-align:  ${props => props.verticalAlign};
  ${props => props.middleT ? 'transform:translate(-50%, -50%);' : ''}

  span {
    font-weight: 900;
    font-style: italic;
  }

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    font-size: ${props => props.mFontSize};
    text-align: ${props => props.mTextAlign};
  }
`;

export const CustomP3 = styled.p`
  position: absolute;
  
  height: ${props => props.height};
  width: ${props => props.width};
  top: ${props => props.top};
  left:  ${props => props.left};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  font-family: 'Mulish', 'Prompt', sans-serif;
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props => props.letterSpacing};
  z-index: ${props => props.zIndex};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  vertical-align:  ${props => props.verticalAlign};
  ${props => props.middleT ? 'transform:translate(-50%, -50%);' : ''}

  background: linear-gradient(180deg, #FCE40A 0%, #FF9900 100%);
  box-shadow: 0px 8px 18px -7px rgba(0, 0, 0, 0.07);
  border-radius: 14px;

  span {
    font-weight: 900;
    font-style: italic;
  }

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth};
    height: ${props => props.mHeight};
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    font-size: ${props => props.mFontSize};
    text-align: ${props => props.mTextAlign};
  }
`;


export const CustomDiv = styled.div`
  position: absolute;
  top: ${props => props.top} ;
  left: ${props => props.left};
  ${props => props.middleT ? 'transform:translate(-50%, -50%);' : ''}

  padding-bottom: ${props => props.paddingTop};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  border-radius: ${props => props.borderRadius};

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    font-size: ${props => props.mFontSize};
    text-align: ${props => props.mTextAlign};
    padding-bottom: ${props => props.mPaddingTop};
    margin-left: ${props => props.mMarginLeft};
    margin-right: ${props => props.mMarginRight};
  }
`

export const Image = styled.img`
  height: auto;
  width: ${props => props.width}; //19.23vw;
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  z-index: ${props => props.zIndex};
  ${props => props.middleT ? 'transform:translate(-50%, -50%)' : ''};
  mix-blend-mode: ${props => props.mixBlendMode};
  border-radius: ${props => props.borderRadius};
  ${props => props.bw ? 'filter: grayscale(100%)' : ''};
  background-color: ${props => props.bgColor};
  ${props => props.addOn};

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    ${props => props.mAddOn};
  }
  
  pointer-events: none;
`;


export const ImageClickable = styled.img`
  height: auto;
  width: ${props => props.width}; //19.23vw;
  position: ${props => props.position ? props.position : 'absolute'};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  z-index: ${props => props.zIndex};
  ${props => props.middleT ? 'transform:translate(-50%, -50%)' : ''};
  mix-blend-mode: ${props => props.mixBlendMode};
  border-radius: ${props => props.borderRadius};
  ${props => props.bw ? 'filter: grayscale(100%)' : ''};
  background-color: ${props => props.bgColor};

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
  }

  :active
  {
    transform: scale(0.9);
  }
`;

export const CustomInput = styled.input`
  position: absolute;
  font-family: 'Poppins', 'Prompt', sans-serif;
  font-size: 1vw;
  font-weight: 500;
  background: white;
  border: none;
  //height: 3.8vw;
  //width: 26vw;
  width: ${props => props.width}; 
  height: ${props => props.height}; 
  top: ${props => props.top};
  left: ${props => props.left};
  //left: 56.5vw;
  //bottom: 40.5vw;
  text-indent: 1.2vw;
  color: #21366C;
  border-radius: 1vw;
  box-shadow: 0px 0.3vw #CFCFCF;
  outline: none;
  text-align: top;
  ${props => props.middleT ? 'transform:translate(-50%, -50%)' : ''};
  //border-radius: 3px;
  ::placeholder {
    color: #21366C;
    margin-left: 1vw;
  }

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    height: ${props => props.mHeight}; 
    font-size: ${props => props.mFontSize}; 
    box-shadow: 0px 0.8vw #CFCFCF;
    border-radius: 2.5vw;
    text-indent: 2vw;
  }
`;

export const CustomTextArea = styled.textarea`
  position: absolute;
  font-family: 'Mulish', 'Prompt', sans-serif;
  font-size: 1vw;
  font-weight: 500;
  background: white;
  border: none;
  //height: 3.8vw;
  //width: 26vw;
  width: ${props => props.width}; 
  height: ${props => props.height}; 
  top: ${props => props.top};
  left: ${props => props.left};
  //left: 56.5vw;
  //bottom: 40.5vw;
  columns: 20;
  white-space: pre-line;
  padding: 1vw;
  color: #21366C;
  border-radius: 1vw;
  box-shadow: 0px 0.3vw #CFCFCF;
  outline: none;
  resize: none;
  
  //border-radius: 3px;
  ::placeholder {
    color: #BBBBBB;
  }

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    padding: 5vw;
    height: ${props => props.mHeight}; 
    border-radius: 5vw;
    font-size: 3.5vw;
    box-shadow: 0px 1.5vw #CFCFCF;
  }
`;

export const CustomButton = styled.button`
  font-family: 'Poppins', 'Prompt', sans-serif;
  font-size: 1.5vw;
  font-size: ${props => props.fontSize}; 
  font-weight: 500;
  font-size: ${props => props.fontWeight}; 

  border-radius: 1vw;
  box-shadow: 0px 0.3vw #DB6624;
  height: ${props => props.height};//3.9vw;
  width: ${props => props.width};//7.4vw;
  background: linear-gradient(180deg, #FCE40A, #FF9900); /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  z-index: 20;
  position: absolute;
  left: ${props => props.left};//83.4vw;
  bottom: ${props => props.bottom};//40.59vw;
  top: ${props => props.top};
  right: ${props => props.right};
  ${props => props.middleT ? 'transform:translate(-50%, -50%)' : ''};

  cursor:pointer;

  :active{
    transform: scale(0.95);

    @media screen and (max-width: 760px) 
    {
      box-shadow: 0px 0.8vw #DB6624, 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }
  }

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    height: ${props => props.mHeight}; 
    font-size: ${props => props.mFontSize}; 
    border-radius: 3vw;
    box-shadow: 0px 0.8vw #DB6624;
  }
`
export const CustomButton2 = styled.button`
  font-family: 'Poppins', 'Prompt', sans-serif;
  font-size: 1.5vw;
  font-size: ${props => props.fontSize}; 
  font-weight: 500;

  border-radius: 1.5vw;

  height: ${props => props.height};//3.9vw;
  width: ${props => props.width};//7.4vw;
  background: linear-gradient(180deg, #FCE40A 0%, #8ACA63 100%);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  z-index: 20;
  position: absolute;
  left: ${props => props.left};//83.4vw;
  bottom: ${props => props.bottom};//40.59vw;
  top: ${props => props.top};
  right: ${props => props.right};
  ${props => props.middleT ? 'transform:translate(-50%, -50%)' : ''};

  cursor:pointer;

  :active{
    box-shadow:  3px 2px 22px 1px rgba(0, 0, 0, 0.24);

    @media screen and (max-width: 760px) 
    {
      box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }
  }

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    height: ${props => props.mHeight}; 
    font-size: ${props => props.mFontSize}; 
    border-radius: 3vw;
  }
`



export const TransButton = styled.button`
  font-family: 'Poppins', 'Prompt', sans-serif;
  font-size: 1vw;
  font-size: ${props => props.fontSize};
  font-weight: 500;
  font-weight: ${props => props.fontWeight};

  border-radius: 1vw;

  height: ${props => props.height};//3.9vw;
  width: ${props => props.width};//7.4vw;
  background: rgba(0, 0, 1, 0);

  border: none;
  color: white;
  color: ${props => props.color};

  text-align: center;
  text-align: ${props => props.textAlign};
  text-decoration: none;
  display: inline-block;
  z-index: 1;
  position: absolute;
  left: ${props => props.left};//83.4vw;
  bottom: ${props => props.bottom};//40.59vw;
  top: ${props => props.top};
  right: ${props => props.right};
  ${props => props.middleT ? 'transform:translate(-50%, -50%)' : ''};
  transform:translate(-50%%, 100%);
  cursor:pointer;

  @media screen and (max-width: 760px) 
  {
    ${props => props.textAlign === 'left' ? 'text-indent: -0.3rem' : ''};
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    height: ${props => props.mHeight}; 
    font-size: ${props => props.mFontSize}; 
  }

  @media screen and (max-device-width: 760px) 
  {
    ${props => props.textAlign === 'left' ? 'text-indent: -1rem' : ''};
  }

  :active
  {
    transform: scaleY(0.95);
  }
`

export const ImgButton = styled.button`
  font-family: 'Poppins', 'Prompt', sans-serif;
  font-size: 1vw;
  font-size: ${props => props.fontSize};
  font-weight: 500;
  font-weight: ${props => props.fontWeight};
  background: ${props => props.Src} no-repeat scroll 0 0 transparent;
  border-radius: 1vw;

  height: ${props => props.height};//3.9vw;
  width: ${props => props.width};//7.4vw;
  background: rgba(0, 0, 1, 1);

  border: none;
  color: white;
  color: ${props => props.color};

  text-align: center;
  text-align: ${props => props.textAlign};
  text-decoration: none;
  display: inline-block;
  z-index: 1;
  position: absolute;
  left: ${props => props.left};//83.4vw;
  bottom: ${props => props.bottom};//40.59vw;
  top: ${props => props.top};
  right: ${props => props.right};
  ${props => props.middleT ? 'transform:translate(-50%, -50%)' : ''};
  transform:translate(-50%%, 100%);
  cursor:pointer;
  background: url(${props => props.img}) no-repeat scroll 0 0;
  background-size: 100% 100%; 

  @media screen and (max-width: 760px) 
  {
    ${props => props.textAlign === 'left' ? 'text-indent: -0.3rem' : ''};
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    height: ${props => props.mHeight}; 
    font-size: ${props => props.mFontSize}; 
  }

  @media screen and (max-device-width: 760px) 
  {
    ${props => props.textAlign === 'left' ? 'text-indent: -1rem' : ''};
  }

  :active
  {
    transform: scale(0.9);
  }
`


export const CustomLine = styled.div`
  position: absolute;
  width: ${props => props.width};;
  height: 0px;
  top: ${props => props.top};
  left: ${props => props.left};
  border: 1px solid #21366C;


  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; 
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
  }
`

function MouseAnimation(props) {
  return (
    <CustomDiv mWidth={props.mWidth} left={props.left} top={props.top} middleT={true}>
      <MouseScrollBorder>
        <MouseScroller></MouseScroller>
      </MouseScrollBorder>
    </CustomDiv>
  )
}
export default MouseAnimation

export const MouseScrollBorder = styled.div`
  width: 0.2vw;
  padding: 0.7vw 1vw;
  height: 2.1vw;
  border: 0.15vw solid #21366C;
  border-radius: 20vw;
  box-sizing: content-box;

  @media screen and (max-width: 760px) 
  {
    width: 0;
  }
`

export const MouseScroller = styled.div`
  width: 0.2vw;
  height: 0.75vw;
  border-radius: 25%;
  background-color: #21366C;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
  @keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(0.9vw); opacity: 0;}
  }
  @media screen and (max-width: 760px) 
  {
    width: 0;
  }
`

export const ReviewSection = styled.section`
  width: ${props => props.width}; 
  height: ${props => props.height}; 
  top: ${props => props.top};
  left: ${props => props.left};
  padding: 0; /* remove any pre-existing padding, just in case */
  padding-bottom: ${props => props.paddingBottom};
  position:relative;
  z-index: ${props => props.zIndex} ;
  overflow:hidden;
  display: "block";
  margin: "auto";

  box-sizing: border-box;

  background: #FFFFFF;
  border: 0.1vw solid #F2F2F2;
  box-shadow: 0px 0.4vw 1vw rgba(0, 0, 0, 0.07);
  border-radius: 0.8vw;


  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth};
    height: ${props => props.mHeight};  //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
  }
`
export const CustomUl = styled.ul`
  white-space: pre-wrap;
  position: absolute;
  height: auto;
  width: ${props => props.width};
  top: ${props => props.top};
  left:  ${props => props.left};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  font-family: 'Mulish', 'Prompt', sans-serif;
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props => props.letterSpacing};
  z-index: 50;
  z-index: ${props => props.zIndex};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  ${props => props.middleT ? 'transform:translate(-50%, -50%);' : ''}
  ${props => props.bottomT ? 'transform:translate(-50%, 100%);' : ''}
  //border-style: dotted;
  padding-left: 0;

  @media screen and (max-width: 760px) 
  {
    width: ${props => props.mWidth}; //19.23vw;
    top: ${props => props.mTop};
    left: ${props => props.mLeft};
    right: ${props => props.mRight};
    bottom: ${props => props.mBottom};
    font-size: ${props => props.mFontSize};
    text-align: ${props => props.mTextAlign};
  }

  @media screen and (max-device-width: 760px) 
  {
    padding-left: 1.5vw;
  }
`;