import { CustomSection, Image, CustomP, ImgButton } from "../../newPageStyle"
import desktopImg from '../../../../assets/footer/item1/desktop.png'
import mobileImg from '../../../../assets/footer/item1/mobile.png'
import { TextData } from '../../../Data/InfoData'
import { useContext } from "react";
import appstoreButton from '../../../../assets/buttons/appstore.png'
import googleButton from '../../../../assets/buttons/googleplay.png'
import { UserContext } from "../../../..";
import { useMediaQuery } from 'react-responsive';
import desktopButton from '../../../../assets/buttons/desktop.png'
import DesktopDownloadButton from "../Components/desktopDownloadButton"

function FooterItem1() {
  const [index] = useContext(UserContext)
  const isIndo = index === 2
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <CustomSection marginTop={'-25vw'} mMarginTop={'-60vw'} paddingBottom={'65vw'} mPaddingBottom={'192vw'}>
      <Image src={desktopImg} width={'100vw'} mWidth={'0vw'} top={'0vw'} left={'0vw'}></Image>
      <Image src={mobileImg} width={'0vw'} mWidth={'100vw'} mTop={'15vw'} mleft={'0vw'}></Image>
      <div style={{ position: 'relative', top: isMobile ? '0px' : '-20px' }}>
        <CustomP width={'50vw'} mWidth={'100vw'} left={'50%'} mLeft={'0%'} top={'13.5vw'} mTop={isMobile && isIndo ? '32vw' : '43vw'} zIndex={10}
          fontWeight={700} fontSize={isIndo ? '2.8vw' : '3.6vw'} mFontSize={'8.2vw'} lineHeight={'120%'} textAlign={'center'} letterSpacing={'0.05vw'}
          color={'#21366C'}>
          {TextData[index].id_29}
        </CustomP>
        <CustomP width={'50vw'} mWidth={'100vw'} left={'50%'} mLeft={'0%'} top={'18vw'} mTop={'54vw'} zIndex={10}
          fontWeight={700} fontSize={isIndo ? '2.6vw' : '2.8vw'} mFontSize={'5.6vw'} lineHeight={'130%'} textAlign={'center'} letterSpacing={'0.05vw'}
          color={'#21366C'}>
          {TextData[index].id_30}
        </CustomP>

        <ImgButton width={'16vw'} mWidth={'36vw'} height={'4vw'} mHeight={'9vw'} top={'26.25vw'} mTop={'72vw'} left={'58.7%'} mLeft={'12%'}
          fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={appstoreButton} onClick={() => window.open("https://apps.apple.com/th/app/miimo-coding-game-for-kids/id1644568803")}>
        </ImgButton>
        <ImgButton width={'16vw'} mWidth={'36vw'} height={'4vw'} mHeight={'9vw'} top={'26.25vw'} mTop={'72vw'} left={'75.7%'} mLeft={'52%'}
          fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={googleButton} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.miimoai.client")}>
        </ImgButton>
        <DesktopDownloadButton width={'16vw'} height={'4vw'} top={'31.25vw'} mTop={'72vw'} left={'67.7%'}
          fontWeight={500} fontSize={'1.65vw'} mFontSize={'5vw'} img={desktopButton} />
      </div>
    </CustomSection>
  )
}

export default FooterItem1