import Slider from "react-slick";
import { CustomSection, CustomHeader, CustomButton } from "../../newPageStyle";
import HeaderItem1 from "./headerItem1";
import HeaderItem2 from "./headerItem2";
import HeaderItem3 from "./headerItem3";
import HeaderItem4 from "./headerItem4";
import { Image, ImageClickable, CustomP } from "../../newPageStyle";
import React, { useLayoutEffect, useState } from 'react';
import styles from '../../styles.css'
import { useMediaQuery } from "react-responsive";

function Header()
{
  React.useEffect(() => {
    function handleResize() {
      setIsResizing(true);
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      setIsResizing(false);
      window.removeEventListener('resize', handleResize)
    }
  })  

  const [isResizing, setIsResizing] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const settings = {
    dots: isMobile ? false : true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    dotsClass: "vertical-dots",
    pauseOnHover: false,
    arrows: false
    ////https://stackoverflow.com/questions/47367725/slick-slider-dots-repositioning-multiple-positions
  };
  return (
    <div>
    <CustomHeader>
      <Slider {...settings}>
        {<HeaderItem1></HeaderItem1>} 
        {<HeaderItem2></HeaderItem2>}
        {<HeaderItem3></HeaderItem3>}
        {<HeaderItem4></HeaderItem4>}
      </Slider>
    </CustomHeader>
    </div>
  );
}

export default Header
      
     /* <ImageClickable src={ENLangIcon} top={'2.4vw'} mTop={'6vw'} right={'2.5vw'} mRight={'3vw'} width={'4.5vw'} mWidth={'14vw'}  zIndex={10} /*onClick={()=> setIndex(0)}*///></ImageClickable>
     /* CustomP top={'0.5vw'} mTop={'1.1vw'} right={'8vw'} mRight={'19vw'} width={'0.5vw'} mWidth={'1vw'} fontSize={'1.64vw'} mFontSize={'4.5vw'} fontWeight={'500'} color={'#21366C'} zIndex={10}>
        |
      </CustomP>
      <ImageClickable src={THLangIcon} top={'2.4vw'} mTop={'6vw'} right={'9.5vw'} mRight={'22vw'} width={'4.5vw'} mWidth={'14vw'}  zIndex={10} /*onClick={()=> setIndex(1)}*///></ImageClickable>*/