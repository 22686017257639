import GlobalStyle from './GlobalStyles';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import AboutUsPage from './components/NewWebsite/AboutUsPage';
function AboutUs() {

  return (
    <div>
      <GlobalStyle></GlobalStyle>
      <AboutUsPage></AboutUsPage>
    </div>
  );
}

export default AboutUs;
