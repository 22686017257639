import React, { useContext } from "react";
import ReactDOM from "react-dom";
import Slider from "react-slick";
import styles from "./Review.module.css";
import { CustomSection, Image, ImageClickable } from "../../newPageStyle";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from 'react-responsive';

import bg from '../../../../assets/appInfo/item3/bg.png'
import leftArrow from '../../../../assets/review/left-arrow.png'
import rightArrow from '../../../../assets/review/right-arrow.png'
import ReviewItem from "./ReviewItem";
import { reviewIndoItems, reviewItems, reviewThItems } from "./ReviewItems";
import { UserContext } from "../../../..";


function Review() {

  const [index] = useContext(UserContext);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const useStyles = createUseStyles({
    sliderContainer: {

      "& .slick-slide section": {
        display: "block",
        margin: "auto"
      },
    },
  });

  function LeftNavButton(props) {
    const { className, style, onClick } = props
    return (
      <div
        className="slick-arrow"
        onClick={onClick}
      >
        <ImageClickable src={leftArrow} width={'2.5vw'} mWidth={'5vw'} top={'15vw'} mTop={'32vw'} left={'-5vw'} />
      </div>
    );
  }

  function RightNavButton(props) {
    const { className, style, onClick } = props
    return (
      <div
        className="slick-arrow"
        onClick={onClick}
      >
        <ImageClickable src={rightArrow} width={'2.5vw'} mWidth={'5vw'} top={'-15.75vw'} mTop={'-41.75vw'} right={'-5vw'} />
      </div>
    );
  }

  const classes = useStyles();
  const settings = {
    className: classes.sliderContainer,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    speed: 500,
    prevArrow: <LeftNavButton />,
    nextArrow: <RightNavButton />
  };


  return (
    <div className="App">
      <Image src={bg} width={'100%'} mWidth={'100%'} top={'-38vw'} mTop={'-43vw'} left={'0%'} zIndex={-20}></Image>
      <CustomSection marginTop={'-5vw'} mMarginTop={'5vw'} paddingBottom={'30vw'} mPaddingBottom={'45vw'}>
        <div className={styles.container}>
          <Slider {...settings}>
            {index === 0 && reviewItems.map((item, index) => (
              <ReviewItem name={item.name} occupation={item.occupation} imgSrc={item.imgSrc} comment={item.comment}></ReviewItem>))
            }
            {index === 1 && reviewThItems.map((item, index) => (
              <ReviewItem name={item.name} occupation={item.occupation} imgSrc={item.imgSrc} comment={item.comment}></ReviewItem>))
            }
            {index === 2 && reviewIndoItems.map((item, index) => (
              <ReviewItem name={item.name} occupation={item.occupation} imgSrc={item.imgSrc} comment={item.comment}></ReviewItem>))
            }
          </Slider>
        </div>
      </CustomSection>
    </div>
  );
}


export default Review